<template>
  <div>
    <h2>Upload Slides</h2>
    <b-form-file ref="formFile" accept="application/pdf" @change="handleFileUpload"></b-form-file>
    <b-input-group v-if="file" class="mt-2">
      <b-input-group-prepend>
        <b-input-group-text>
          Name:
        </b-input-group-text>
      </b-input-group-prepend>
      <b-input v-model="name"/>  
    </b-input-group>
    <div>{{ status }}</div>
    <b-progress :value="completion" max="1.0"></b-progress>
    <p class="mt-2">Preview</p>
    <slides-viewer :slides="slides" />
    <b-button @click="create" :disabled="!slides">Create</b-button>
  </div>
</template>

<script>
import { getLog } from '@/services/log';
let log = getLog('create-slides');
import { db, storage } from '@/services/db';
import { randomString } from '@/services/utils';
import { callable } from '@/services/functions';
import { appConfig } from '@/services/appconfig';

export default {
  components: {
    'slides-viewer': () => import('@/components/slidesViewer.vue'),
  },
  data() {
    return {
      name: "Untitled",      
      slides: [],
      status: "Ready.",
      completion: 0,
      file: null,
    };
  },
  mounted() {
    log.log('mounted');
    import("pdfjs-dist").then(async (pdfjsLib) => {
      log.log('pdfjsLib loaded');
      this.pdfjsLib = pdfjsLib;
      const pdfjs = await import('pdfjs-dist/build/pdf');
      const pdfjsWorker = await import('pdfjs-dist/build/pdf.worker.entry');
      pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
    });
  },
  methods: {
    async handleFileUpload(event) {
      log.log('handleFileUpload', event);
      this.status = "Loading...";
      this.file = event.target.files[0];
      this.name = this.file.name;
      let pdfDataUrl = await this.convertFileToDataUrl(this.file);
      this.pdfjsLib.getDocument({ url: pdfDataUrl }).promise.then(async (pdf) => {
        //log.log('pdf', pdf);
        this.status = "Extracting pages...";
        let pages = await this.extractPages(pdf);
        log.log('pages', pages);
        this.slides = await Promise.all(pages);
      });
    },
    convertFileToDataUrl(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    },
    dataURLtoBlob(dataurl) {
      var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
    async extractPages(pdf) {
      const totalNumPages = pdf.numPages;
      const pages = [];
      for (let pageNumber = 1; pageNumber <= totalNumPages; pageNumber++) {
        log.log('extracting page', pageNumber);
        this.status = `Extracting page ${pageNumber} of ${totalNumPages}`;
        this.completion = pageNumber / totalNumPages;
        const page = await pdf.getPage(pageNumber);
        let image = this.convertPageToImage(page);
        pages.push(image);
      }
      return pages;
    },
    async convertPageToImage(page) {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');

      const viewport = page.getViewport({ scale: 4 });
      log.log('viewport', viewport);
      canvas.width = viewport.width;
      canvas.height = viewport.height;

      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      };

      await page.render(renderContext).promise;
      return canvas.toDataURL('image/png');
    },
    create() {
      log.log('create');
      let files = this.slides.map((slide, index) => {
        let dataUrl = slide;
        let file = this.dataURLtoBlob(dataUrl);
        file.name = `slide-${index}.png`;
        log.log('file converted', file.name);
        return file;
      });
      this.upload(files);
    },
    async makePublic(videoId) {
      let input = {videoId, type: "slides"};
      log.log("makePublic", input);
      let makePublic = callable('vid-makePublic');
      let {data} = await makePublic(input);
      if (data.count < 1)
        log.error("makePublic failed", data);
      log.log("makePublic res", data);
    },
    async upload(files) {
      let assetId = randomString(32);
      let pathPrefix = `s2/slides/${assetId}/`;
      this.status = "Uploading...";
      this.total_files_count = files.length;
      this.completed_files_count = 0;
      // upload files to firebase storage
      await Promise.all(files.map(async (file) => {
        const path = pathPrefix + file.name;
        log.log("started uploading:", path);
        await storage.ref(path).put(file);
        log.log("finished uploading:", path);
        this.completed_files_count++;
        this.completion = this.completed_files_count / this.total_files_count;
      }));
      await this.makePublic(assetId);
      this.status = "Done";
      let url = `${appConfig.contentServerHost}/${pathPrefix}`;
      log.log(url);
      db.collection('S2Videos').doc(assetId).set({
        name: this.name,
        url: url,
        type: "slides",
        numSlides: files.length,
        ownerId: this.$store.account.uid,
        filename: this.file.name,
        created: new Date(),
      });
    }
  }
};
</script>
